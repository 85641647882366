import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { Container, Row, Col, TabContent, Nav, NavItem, NavLink, TabPane } from 'reactstrap';

import Layout from '../components/Layout'
import Slider from '../components/Slider';
import Features from '../components/Feartures';
import Piso from '../components/Piso';

export const ApartmentPageTemplate = (props) => {

  const [activeTab, setActiveTab] = useState(0);

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  return  (
    <section id="apartments">
        <Row>
          <Col className="title">
            <h1>{props.title}</h1>
          </Col>
        </Row>
        <Row className="floors">
          <Col md={12}>
            <Nav tabs>
              {props.pisos.map( (piso, i) => {
                return (
                  <NavItem key={i}>
                      <NavLink
                          className={activeTab === i ? 'active' : ''}
                          onClick={() => { toggle(i); }} >
                        {piso.name}
                      </NavLink>
                  </NavItem>
                )
              })}
            </Nav>
            <TabContent activeTab={activeTab}>
              {props.pisos.map( (piso, i) => {
                return (
                  <TabPane tabId={i}>
                    <Piso key={i} piso={piso} />
                  </TabPane>
                )
              })}
            </TabContent>
          </Col>
        </Row>
    </section>
  )
}

ApartmentPageTemplate.propTypes = {
  title: PropTypes.string,
  pisos: PropTypes.array
}

const ApartmentPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout currentSlug={frontmatter.title} title={frontmatter.title} description={frontmatter.description}>
      <ApartmentPageTemplate
        title={frontmatter.title}
        pisos={frontmatter.pisos}
      />
    </Layout>
  )
}

ApartmentPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ApartmentPage

export const pageQuery = graphql`
  query ApartmentPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "apartments-page" } }) {
      frontmatter {
        title
        description
        pisos {
          name
          title
          image
          types {
            title
            name
            image
          }
        }
      }
    }
  }
`