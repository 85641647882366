import { Link } from 'gatsby'
import React, { Component } from 'react'
import { Col, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter, Row, NavLink } from 'reactstrap'

class Piso extends Component {
    constructor(props) {
        super(props)
        this.piso = props.piso
        this.types = this.piso.types
        this.state = {
            type: 0,
            isOpen: false
        }
        this.toggle = this.toggle.bind(this);
        this.showModal = this.showModal.bind(this);
    }

    toggle() {
        this.setState({
          isOpen: !this.state.isOpen
        });
      }

    showModal(e) {
        let value = e.target.value;
        this.setState({
            type: value,
            isOpen: !this.state.isOpen
        })
        e.target.value = ""
    }

    render() {
        let options = this.piso.types.map((type, i) => { return (<option key={i} value={i}>{type.name}</option>)})
        return (
            <div>
                <Modal isOpen={this.state.isOpen} toggle={this.toggle} backdrop={true} keyboard={true}>
                    {/* <ModalHeader toggle={this.toggle}>{this.types[this.state.type].title}</ModalHeader> */}
                    <ModalBody>
                        <img src={this.types[this.state.type].image} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.toggle}>Cerrar</Button>
                        <Link color="info" to="/virtualShow" style={this.types[this.state.type].title === 'TIPO 4'?{}:{display: 'none'}} >Ver Video</Link>
                    </ModalFooter>
                </Modal>
                <Row>
                    <Col md={2} xs={12} className="selector">
                        <div>
                            <h1>{this.piso.title}</h1>
                            Escoge un tipo:
                            <Input type="select" name="select" id="typeSelect" onChange={this.showModal} >
                                <>
                                    <option hidden disabled selected value> -- select an option -- </option>
                                    {options}
                                </>
                            </Input>
                        </div>
                    </Col>
                    <Col md={9}>
                        <img src={this.piso.image} />
                        <div style={{
                            display: "inline-flex",
                            flexDirection: "row",
                            textAlign: "center",
                            verticalAlign: "middle",
                            alignItems: "center",
                            margin: "1.5em",
                        }}>
                            <p style={{margin: '0'}}>Apartamentos vendidos sombreados</p>
                            <div style={{
                                borderRadius:50, 
                                backgroundColor: 'rgba(0, 0, 0, 0.5)', 
                                height: "1em",
                                width: "1em",
                                margin: "0 1em"}} />
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Piso